<template>
  <label
    :for="inputId"
    class="mt-0 relative default"
    :class="{ disabled: disabled, 'circle-checkbox': circle, indeterminate: indeterminate }"
  >
    <input
      :id="inputId"
      type="checkbox"
      :name="inputName"
      :checked="value"
      :disabled="disabled"
      @click.stop
      @change="$emit('input', $event.target.checked)"
    />
    <slot
      v-if="$slots['label']"
      name="label"
    />
    <span
      v-else-if="label !== 'Checkbox'"
      class="ml-1"
    >
      {{ label }}
    </span>
  </label>
</template>

<script>
export default {
  name: 'CheckBox',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    indeterminate: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    inputId: {
      type: String,
      required: true,
    },
    inputName: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: 'Checkbox',
    },
    circle: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input'],
}
</script>

<style lang="scss" scoped>
.container {
  @apply flex items-center;
}

label {
  @apply flex items-center mb-0;
  @apply cursor-pointer text-bb-mid-grey;

  &.disabled:hover {
    @apply cursor-default bg-neutral-50;
  }
}

input[type='checkbox'] {
  position: relative;
  border: 1px solid $bb-disabled-gray;
  border-radius: 2px;
  background: white;
  cursor: pointer;
  line-height: 0;
  outline: 0;
  padding: 0 !important;
  vertical-align: text-top;
  height: 16px;
  width: 16px;
  -webkit-appearance: none;
  opacity: 0.5;
}

input[type='checkbox']:hover {
  opacity: 1;
}

input[type='checkbox']:checked {
  background-color: $bb-brand-purple;
  border: 1px solid $bb-brand-purple;
  opacity: 1;
}

input[type='checkbox']:before {
  content: '';
  position: absolute;
  right: 54%;
  top: 50%;
  width: 5px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  margin: -1px -1px 0 -1px;
  transform: rotate(45deg) translate(-50%, -50%);
  z-index: 2;
}

.circle-checkbox input[type='checkbox'] {
  border-radius: 50%;
}

.indeterminate {
  input[type='checkbox']:before {
    border: 1px solid $bb-disabled-gray;
    content: '';
    position: absolute;
    right: 50%;
    top: 50%;
    width: 10px;
    height: 2px;
    transform: rotate(0deg) translate(50%, 0%);
    background-color: #fff;
    margin: -1px 0 0 -5px;
    z-index: 2;
  }
  input[type='checkbox']:hover {
    border: 1px solid $bb-brand-purple;
  }
}

.disabled {
  input[type='checkbox']:before {
    content: '';
    position: absolute;
    right: 50%;
    top: 50%;
    width: 10px;
    height: 2px;
    transform: rotate(0deg) translate(50%, 0%);
    background-color: $neutral-50;
    margin: -1px 0 0 -5px;
    z-index: 2;
  }
  input[type='checkbox']:hover {
    opacity: 0.5;
  }
}
</style>
