<template>
  <div class="text-bb-text-default px-6 pt-8 space-y-4">
    <p class="h5">Apply all budget suggestions?</p>
    <p>Confirm to apply all the new budget suggestions to your Google Ads account</p>

    <div class="flex justify-between items-center pt-2">
      <merge-button-round
        button-type="secondary"
        @click="$emit('close')"
      >
        <p>Cancel</p>
      </merge-button-round>
      <merge-button-round
        button-type="primary"
        @click="$emit('confirm')"
      >
        <p>Confirm</p>
      </merge-button-round>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ApplyAllBudget',
}
</script>

<style scoped lang="scss"></style>
