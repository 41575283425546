<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 19H10V5H6V19ZM14 5V19H18V5H14Z"
      fill="#3E4552"
    />
  </svg>
</template>
<script>
export default {
  name: 'IcPause',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
}
</script>
