<template>
  <div
    class="card-base border-transparent border-l-8 duration-200 ease-linear"
    :class="{
      'border-bb-brand-purple': !hoverDisabled && (isExpanded || isChecked),
      'hover:border-neutral-100': disabledCheckbox && !hoverDisabled,
      'hover:border-bb-brand-purple': !disabledCheckbox && !hoverDisabled,
    }"
  >
    <div
      class="flex flex-row items-center overflow-hidden space-x-4 cardBase"
      @click="cardClick"
    >
      <CheckBox
        v-if="checkbox"
        :disabled="disabledCheckbox"
        input-name="base-card"
        :input-id="id"
        :value="isChecked"
        class="h-4"
        @input="cardSelect"
      />
      <!-- Left Section A -->
      <div class="w-full min-w-32 max-w-80 xl:max-w-90">
        <slot name="first" />
      </div>

      <!-- Middle Scrollable Section -->
      <div class="w-full overflow-x-auto base-scrollbar midSec">
        <div
          ref="contentContainer"
          class="flex flex-row justify-end space-x-8"
        >
          <slot name="content" />
        </div>
      </div>

      <!-- Right Section B -->
      <div class="my-auto">
        <slot name="action" />

        <div
          v-if="curtain"
          @click.stop="toggleExpansion"
        >
          <IcChevron
            :direction="isExpanded ? 'up' : 'down'"
            class="cursor-pointer"
          />
        </div>
      </div>
    </div>
    <div
      v-if="isExpanded"
      class="my-6 w-full overflow-x-auto base-scrollbar"
    >
      <slot name="expanded-content" />
    </div>
  </div>
</template>

<script>
import IcChevron from '@/components/icon/ic-chevron.vue'
import CheckBox from '@/components/input/brightbid/CheckBox.vue'

export default {
  components: {
    CheckBox,
    IcChevron,
  },
  props: {
    checkbox: {
      type: Boolean,
      default: true,
    },
    curtain: {
      type: Boolean,
      default: true,
    },
    isChecked: {
      type: Boolean,
      default: false,
    },
    disabledCheckbox: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: 'base-card',
    },
    hoverDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isExpanded: false,
      isHovered: false,
    }
  },
  mounted() {
    this.adjustMiddleSectionWidth()
  },
  methods: {
    toggleExpansion() {
      this.isExpanded = !this.isExpanded
      if (this.isExpanded) {
        this.$emit('toggleExpansion', this.isExpanded)
      }
    },
    cardSelect() {
      this.$emit('cardSelect')
    },
    cardClick() {
      this.$emit('cardClick')
    },
    adjustMiddleSectionWidth() {
      this.$nextTick(() => {
        let totalWidth = 0
        // Assuming each direct child of the slot contributes to the total width
        const contentContainer = this.$refs.contentContainer // Make sure to add this ref in your template
        if (contentContainer) {
          const children = contentContainer.children
          for (const child of children) {
            totalWidth += child.offsetWidth + this.calculateMargins(child)
          }
          // Directly update the style or a reactive property to trigger the update
          contentContainer.style.minWidth = `${totalWidth}px`
        }
      })
    },
    calculateMargins(element) {
      const style = window.getComputedStyle(element)
      return parseInt(style.marginLeft) + parseInt(style.marginRight)
    },
  },
}
</script>

<style scoped lang="scss">
.card-base {
  @apply relative rounded-lg p-6 overflow-x-auto;
  box-shadow: 0px 7px 12px 0px #8898aa26;
  transition: all 300ms ease;

  &:before,
  &:after {
    content: '';
    position: absolute;
    pointer-events: none;
    transition: all 300ms ease;
  }

  &:before {
    width: 0;
    height: 100%;
    top: 0;
    left: 0;
    background-color: transparent;
  }

  &:after {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: transparent;
  }
}
</style>
