import { render, staticRenderFns } from "./ic-sort.vue?vue&type=template&id=657ed7bb&scoped=true"
import script from "./ic-sort.vue?vue&type=script&lang=js"
export * from "./ic-sort.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "657ed7bb",
  null
  
)

export default component.exports