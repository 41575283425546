<template id="overwrite-existing-draft-modal">
  <div
    class="relative modal-container z-50"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div class="fixed inset-0 bg-bb-cool-grey bg-opacity-25 transition-opacity" />
    <div class="fixed inset-0 overflow-y-auto base-scrollbar">
      <div class="flex items-center justify-center min-h-full p-4 text-center">
        <!--MODAL-->
        <div
          class="bg-white rounded-lg text-left shadow-xl transform transition-all"
          :style="{ width: width, height: height }"
        >
          <!--CLOSE BUTTON-->
          <ic-cross
            class="absolute top-6 right-6"
            @close="$emit('close')"
          />
          <!--MAIN CONTENT-->
          <slot
            name="container"
            :loading-status="isModalLoading"
            :form="formValues"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IcCross from '@/components/icon/ic-cross'

export default {
  name: 'BbBaseModal',
  components: { IcCross },
  props: {
    width: {
      type: [String, Number],
      default: 500,
    },
    height: {
      type: [String, Number],
      default: 500,
    },
    isModalLoading: {
      type: Boolean,
      default: false,
    },
    formValues: {
      type: Object,
      default: () => {},
    },
  },
}
</script>

<style scoped>
.modal-container {
    z-index: 9000;}
</style>
