<template>
  <bb-base-modal
    v-if="showModal"
    width="460px"
    height="auto"
    @close="$emit('close')"
  >
    <template #container>
      <div class="p-8 grid gap-y-6">
        <div class="text-xl text-center font-medium">Select Campaign Strategy</div>
        <div class="text-left">
          To achieve optimal performance with BrightBid's AI bid adjustments, we recommend pairing it with Google's
          Maximize Clicks strategy.
        </div>
        <div class="text-left pb-2">
          You can either switch to the Maximize Clicks bidding strategy or continue using your current automated Google
          strategy without the AI bid modifications.
        </div>
        <div class="text-center px-10">
          <merge-button-round
            :disabled="isLoading"
            button-type="brand-purple"
            class="w-full"
            @click="submit(true)"
          >
            <p>Use AI Bid modifications</p>
          </merge-button-round>
          <p class="text-bb-neutral-gray text-xs mt-2">This will change the Google Strategy to Maximize clicks</p>
        </div>
        <div class="text-center px-10">
          <merge-button-round
            :disabled="isLoading"
            button-type="primary"
            class="w-full"
            @click="submit(false)"
          >
            <p>Use Google bidding Strategy</p>
          </merge-button-round>
          <p class="text-bb-neutral-gray text-xs mt-2">This will turn off the BrightBid Ai bidding modifications</p>
        </div>
      </div>
    </template>
  </bb-base-modal>
</template>

<script>
import MergeButtonRound from '@/components/btn/MergeButtonRound.vue'
import BbBaseModal from '@/components/modals/brightbid/BbBaseModal'

export default {
  name: 'BidStrategyModalMismatch',
  components: {
    MergeButtonRound,
    BbBaseModal,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    submit(useBrightBidStrategy) {
      const payload = {
        use_bright_bid_strategy: useBrightBidStrategy,
      }
      this.$emit('submit', payload)
    },
  },
}
</script>
