<template>
  <base-modal>
    <template #header>
      <div class="flex justify-between">
        <div class="text-gray-700 text-xl font-medium">Activity History</div>
        <ic-close
          class="text-right"
          @click="$emit('close')"
        />
      </div>
    </template>
    <template #default>
      <div>
        <div class="mb-5">
          <date-picker
            ref="startDatePicker"
            v-model="datePicker"
            :clearable="false"
            range
            :shortcuts="shortcuts"
            class="mt-2"
            @input="dateRangeChanged"
          ></date-picker>
        </div>
        <bb-table
          ref="bbTable"
          class="border rounded-md"
          :headers="headers"
          :data="tableRows"
          :is-loading="isLoading"
          :scroll-y="true"
          :sticky-header="true"
          table-height="400px"
        >
          <template #rows="{ tableData }">
            <ActivityHistoryRows :data="tableData" />
          </template>
        </bb-table>
      </div>
    </template>
  </base-modal>
</template>

<script>
import IcClose from 'vue-material-design-icons/Close.vue'
import dayjs from 'dayjs'
import BbTable from '@/components/table/BbTable.vue'
import { mapState } from 'vuex'
import ActivityHistoryRows from '@/components/table/table_rows/ActivityHistoryRows.vue'
import DatePicker from 'vue2-datepicker'
import Toast from '@/components/shared/Toast.vue'

export default {
  name: 'ActivityHistoryModal',
  components: {
    IcClose,
    BbTable,
    ActivityHistoryRows,
    DatePicker,
  },
  modalProps: {
    height: 'auto',
    maxWidth: 900,
    scrollable: true,
  },
  data() {
    return {
      dailyBudget: 0,
      isLoading: false,
      headers: [
        {
          value: 'campaign_name',
          label: 'Campaign Name',
          sortable: true,
          sorting: null,
          position: 'left',
        },

        {
          value: 'previous_budget',
          label: 'Previous Budget',
          sortable: false,
          sorting: null,
          position: 'left',
        },
        {
          value: 'adjusted_budget',
          label: 'Adjusted Budget',
          sortable: false,
          sorting: null,
          position: 'left',
        },
        {
          value: 'date',
          label: 'Date',
          sortable: false,
          sorting: null,
          position: 'left',
        },
      ],
      tableRows: [],
      hoveredRow: null,
      datePicker: null,
      dateRange: null,
      shortcuts: [
        {
          text: 'Last 7 Days',
          onClick: () => {
            const today = new Date()
            const last7Days = new Date()
            last7Days.setDate(today.getDate() - 6)
            return [last7Days, today]
          },
        },
        {
          text: 'Last 14 Days',
          onClick: () => {
            const today = new Date()
            const last14Days = new Date()
            last14Days.setDate(today.getDate() - 13)
            return [last14Days, today]
          },
        },
        {
          text: 'Last 30 Days',
          onClick: () => {
            const today = new Date()
            const last30Days = new Date()
            last30Days.setDate(today.getDate() - 29)
            return [last30Days, today]
          },
        },
      ],
    }
  },
  computed: {
    ...mapState('site', ['selectedSite']),
  },
  async mounted() {
    const today = dayjs()
    const endDate = today
    const startDate = today.subtract(30, 'days')
    this.datePicker = [startDate.toDate(), endDate.toDate()]
    this.dateRange = this.datePicker.map(date => new Date(date).toISOString().slice(0, 10))
    await this.getActivityHistory(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'))
  },
  methods: {
    formattedDate(date) {
      return dayjs(date).format('DD/MM/YYYY')
    },
    async getActivityHistory(startDate, endDate) {
      try {
        this.isLoading = true

        const { data } = await this.$http.get(
          `common/site/${this.selectedSite.value}/budget-suggestion/activity-history?start_date=${startDate}&end_date=${endDate}`,
        )
        this.tableRows = data.map(row => {
          return {
            campaignName: row.campaign_name,
            previousBudget: row.previous_budget,
            adjustedBudget: row.adjusted_budget,
            date: row.created_at,
          }
        })
      } catch (error) {
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: 'Failed to update the campaign budget',
            type: 'error',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async dateRangeChanged(dateRange) {
      if (dateRange.some(date => !date)) return
      this.dateRange = dateRange.map(date => new Date(date).toISOString().slice(0, 10))
      await this.getActivityHistory(this.dateRange[0], this.dateRange[1])
    },
  },
}
</script>
<style lang="scss">
$primary-color: $bb-brand-purple;
@import 'node_modules/vue2-datepicker/scss/index.scss';

.noteBox {
  background: #f9f8ff;
}
header {
  padding-bottom: 0px !important;
}

.mx-input {
  border-radius: 8px;
  height: 40px;
  border: #e5e7eb solid 1px;
  padding: 8px 30px;
  font-size: 14px;
  width: 100%;
}
.mx-datepicker-popup {
  z-index: 99999;
}
</style>
