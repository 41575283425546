<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.666 5.33317L9.99935 7.99984H11.9993C11.9993 10.2065 10.206 11.9998 7.99935 11.9998C7.32602 11.9998 6.68602 11.8332 6.13268 11.5332L5.15935 12.5065C5.97935 13.0265 6.95268 13.3332 7.99935 13.3332C10.946 13.3332 13.3327 10.9465 13.3327 7.99984H15.3327L12.666 5.33317ZM3.99935 7.99984C3.99935 5.79317 5.79268 3.99984 7.99935 3.99984C8.67268 3.99984 9.31268 4.1665 9.86602 4.4665L10.8393 3.49317C10.0193 2.97317 9.04601 2.6665 7.99935 2.6665C5.05268 2.6665 2.66602 5.05317 2.66602 7.99984H0.666016L3.33268 10.6665L5.99935 7.99984H3.99935Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IcRefresh',
  props: {
    size: {
      type: [String, Number],
      default: 16,
    },
  },
}
</script>

<style scoped lang="scss"></style>
