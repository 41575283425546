<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 12C18 12.67 18 13.33 18 14C19.2 14 20.76 14 22 14C22 13.33 22 12.67 22 12C20.76 12 19.2 12 18 12Z"
      fill="currentColor"
    />
    <path
      d="M16 18.61C16.96 19.32 18.21 20.26 19.2 21C19.6 20.47 20 19.93 20.4 19.4C19.41 18.66 18.16 17.72 17.2 17C16.8 17.54 16.4 18.08 16 18.61Z"
      fill="currentColor"
    />
    <path
      d="M20.4 6.6C20 6.07 19.6 5.53 19.2 5C18.21 5.74 16.96 6.68 16 7.4C16.4 7.93 16.8 8.47 17.2 9C18.16 8.28 19.41 7.35 20.4 6.6Z"
      fill="currentColor"
    />
    <path
      d="M4 10C2.9 10 2 10.9 2 12V14C2 15.1 2.9 16 4 16H5V20H7V16H8L13 19V7L8 10H4ZM9.03 11.71L11 10.53V15.47L9.03 14.29L8.55 14H4V12H8.55L9.03 11.71Z"
      fill="currentColor"
    />
    <path
      d="M15.5 13C15.5 11.67 14.92 10.47 14 9.65V16.34C14.92 15.53 15.5 14.33 15.5 13Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IcCampaign',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
}
</script>

<style scoped lang="scss"></style>
