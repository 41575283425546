<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 2C5.68667 2 3 4.68667 3 8H1L3.59333 10.5933L3.64 10.6867L6.33333 8H4.33333C4.33333 5.42 6.42 3.33333 9 3.33333C11.58 3.33333 13.6667 5.42 13.6667 8C13.6667 10.58 11.58 12.6667 9 12.6667C7.71333 12.6667 6.54667 12.14 5.70667 11.2933L4.76 12.24C5.84667 13.3267 7.34 14 9 14C12.3133 14 15 11.3133 15 8C15 4.68667 12.3133 2 9 2ZM8.33333 5.33333V8.66667L11.1667 10.3467L11.68 9.49333L9.33333 8.1V5.33333H8.33333Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IcHistory',
  props: {
    size: {
      type: [String, Number],
      default: 16,
    },
  },
}
</script>

<style scoped lang="scss"></style>
