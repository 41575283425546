<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 11.5H23V13.5H20V11.5ZM1 11.5H4V13.5H1V11.5ZM13 1.5V4.5H11V1.5H13ZM4.92 4L7.05 6.14L5.63 7.55L3.5 5.43L4.92 4ZM16.95 6.13L19.07 4L20.5 5.43L18.37 7.55L16.95 6.13ZM12 6.5C13.5913 6.5 15.1174 7.13214 16.2426 8.25736C17.3679 9.38258 18 10.9087 18 12.5C18 14.72 16.79 16.66 15 17.7V19.5C15 19.7652 14.8946 20.0196 14.7071 20.2071C14.5196 20.3946 14.2652 20.5 14 20.5H10C9.73478 20.5 9.48043 20.3946 9.29289 20.2071C9.10536 20.0196 9 19.7652 9 19.5V17.7C7.21 16.66 6 14.72 6 12.5C6 10.9087 6.63214 9.38258 7.75736 8.25736C8.88258 7.13214 10.4087 6.5 12 6.5ZM14 21.5V22.5C14 22.7652 13.8946 23.0196 13.7071 23.2071C13.5196 23.3946 13.2652 23.5 13 23.5H11C10.7348 23.5 10.4804 23.3946 10.2929 23.2071C10.1054 23.0196 10 22.7652 10 22.5V21.5H14ZM11 18.5H13V16.37C14.73 15.93 16 14.36 16 12.5C16 11.4391 15.5786 10.4217 14.8284 9.67157C14.0783 8.92143 13.0609 8.5 12 8.5C10.9391 8.5 9.92172 8.92143 9.17157 9.67157C8.42143 10.4217 8 11.4391 8 12.5C8 14.36 9.27 15.93 11 16.37V18.5Z"
      fill="#6366FA"
    />
  </svg>
</template>
<script>
export default {
  name: 'IcLight',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
}
</script>
