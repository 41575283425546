<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.0714 7.09174C18.8604 7.09174 19.5 6.45212 19.5 5.66318C19.5 4.87423 18.8604 4.23461 18.0714 4.23461C17.2825 4.23461 16.6428 4.87423 16.6428 5.66318C16.6428 6.45212 17.2825 7.09174 18.0714 7.09174ZM7.95107 6.65567L12.3335 2.27319C12.6679 1.93888 13.1321 1.73463 13.6428 1.73463H20.1428C20.387 1.73378 20.6288 1.78123 20.8545 1.87426C21.0802 1.96729 21.2853 2.10405 21.4579 2.27667C21.6305 2.44929 21.7673 2.65435 21.8603 2.88005C21.9534 3.10575 22.0008 3.34763 22 3.59175V10.0917C22 10.6025 21.7957 11.0668 21.4521 11.401L17.0763 15.781L7.95107 6.65567Z"
      fill="#4285F4"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.0763 15.781L11.6757 21.1867C11.3321 21.521 10.8678 21.7346 10.3572 21.7346C9.84646 21.7346 9.38213 21.521 9.04786 21.1867L2.54783 14.6868C2.20425 14.3525 2 13.8882 2 13.3775C2 12.8575 2.21356 12.3932 2.54783 12.0589L7.95107 6.65567L17.0763 15.781Z"
      fill="#34A853"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.70213 17.841L2.54783 14.6867C2.20425 14.3525 2 13.8882 2 13.3775C2 12.8575 2.21356 12.3932 2.54783 12.0589L7.95107 6.65571L12.4192 11.124L5.70213 17.841Z"
      fill="#FBBC05"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21.3587 2.18446C21.0209 1.89283 20.5891 1.73308 20.1428 1.73463H13.6428C13.1321 1.73463 12.6679 1.93888 12.3335 2.27319L7.95107 6.65567L12.4192 11.1239L16.9701 6.57307C16.7581 6.31728 16.6424 5.99537 16.6429 5.66318C16.6429 4.87423 17.2825 4.23461 18.0714 4.23461C18.4036 4.2341 18.7255 4.34985 18.9813 4.56181L21.3587 2.18446Z"
      fill="#EA4335"
    />
    <path
      d="M12.4167 14.5322C14.0193 14.5322 15.3184 13.233 15.3184 11.6304C15.3184 10.0278 14.0193 8.72866 12.4167 8.72866C10.8141 8.72866 9.51489 10.0278 9.51489 11.6304C9.51489 13.233 10.8141 14.5322 12.4167 14.5322Z"
      fill="#4285F4"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.3701 10.7768H12.5108V12.4327H14.7323C14.5252 13.4846 13.6592 14.0885 12.5108 14.0885C11.1553 14.0885 10.0634 12.9586 10.0634 11.556C10.0634 10.1534 11.1553 9.02357 12.5108 9.02357C13.0944 9.02357 13.6215 9.23787 14.0357 9.58852L15.2406 8.34173C14.5064 7.6794 13.565 7.27031 12.5108 7.27031C10.214 7.27031 8.36903 9.17942 8.36903 11.556C8.36903 13.9326 10.214 15.8418 12.5108 15.8418C14.5817 15.8418 16.4643 14.2833 16.4643 11.556C16.4643 11.3028 16.4266 11.0301 16.3701 10.7768Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'GoogleShoppingTag',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
}
</script>

<style scoped lang="scss"></style>
