<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.00016 0.333374C3.32016 0.333374 0.333496 3.32004 0.333496 7.00004C0.333496 10.68 3.32016 13.6667 7.00016 13.6667C10.6802 13.6667 13.6668 10.68 13.6668 7.00004C13.6668 3.32004 10.6802 0.333374 7.00016 0.333374ZM7.66683 10.3334H6.3335V6.33337H7.66683V10.3334ZM7.66683 5.00004H6.3335V3.66671H7.66683V5.00004Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IcInfoSolid',
  props: {
    size: {
      type: [String, Number],
      default: 20,
    },
  },
}
</script>

<style scoped lang="scss"></style>
