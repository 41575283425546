<template>
  <div
    v-click-outside="clickOutside"
    class="relative w-full"
    :class="{ 'text-bb-disabled-buttons cursor-not-allowed': disabled }"
  >
    <div class="flex items-center space-x-2">
      <slot
        class="p-2"
        name="icon"
      ></slot>
      <div
        class="cursor-pointer bb-text-headers font-medium flex gap-x-1 pr-1"
        @click="disabled ? null : (isDropdownVisible = !isDropdownVisible)"
      >
        <p>{{ title }} {{ title ? '' : '' }}</p>
        {{ selectionLabel }}
        <DropdownArrow
          :direction="isDropdownVisible ? 'up' : 'down'"
          :size="24"
        />
      </div>
    </div>
    <div
      v-if="isDropdownVisible"
      class="absolute max-h-50 bg-white rounded-md shadow-md my-2 dropdown-scrollbar overflow-y-auto mr-2"
      :class="dropdownPosition[position]"
    >
      <slot name="dropdown">
        <div
          v-for="option in options"
          :key="option.value"
          class="py-2 px-3 hover:bg-bb-secondary-purple cursor-pointer"
          @click="selectItem(option)"
        >
          <p>{{ option.label }}</p>
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
import DropdownArrow from '@/components/icon/brightbid/dropdown_arrow.vue'

export default {
  name: 'SelectDropdown',
  components: {
    DropdownArrow,
  },
  props: {
    selectionValue: { type: Object, required: true },
    position: { type: String, default: 'bottomLeftToRight' },
    title: { type: String, default: '' },
    options: { type: Array, required: true },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      selectedItem: { label: '', value: '' },
      isDropdownVisible: false,
    }
  },
  computed: {
    selectionLabel() {
      return this.selectedItem.label
    },
    hasIconSlot() {
      return !!this.$slots.icon
    },
    dropdownPosition() {
      return {
        bottomRightToLeft: this.hasIconSlot ? ['-top-0', 'right-6'] : ['-top-0', 'right-0'],
        bottomLeftToRight: this.hasIconSlot ? ['-top-0', 'left-6'] : ['-top-0', 'left-0'],
      }
    },
  },
  mounted() {
    this.selectedItem = this.selectionValue
  },
  methods: {
    selectItem(item) {
      this.selectedItem = item
      this.isDropdownVisible = false
      this.$emit('select-item', this.selectedItem)
    },
    clickOutside() {
      if (!this.isDropdownVisible) return
      this.isDropdownVisible = false
    },
  },
}
</script>
<style scoped lang="scss"></style>
