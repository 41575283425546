<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5758 5.5L15.4842 7.40833L11.4175 11.475L8.08418 8.14167L1.90918 14.325L3.08418 15.5L8.08418 10.5L11.4175 13.8333L16.6675 8.59167L18.5758 10.5V5.5H13.5758Z"
      fill="#18B070"
    />
  </svg>
</template>
<script>
export default {
  name: 'IcStatsUp',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
}
</script>
