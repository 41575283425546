<template>
  <div class="flex items-center text-sm gap-2">
    <div
      v-if="status"
      class="h-2 w-2 rounded-full"
      :class="statusStyling.background"
    />
    <slot
      name="status"
      :status="status"
    >
      <p
        v-if="showStatus"
        class="capitalize"
      >
        {{ status ? status : '-' }}
      </p>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'StatusComponent',
  props: {
    status: {
      type: String,
      required: true,
    },
    showStatus: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    statusStyling() {
      switch (this.status) {
        case 'active':
        case 'positive':
        case 'live':
          return { background: 'bg-success' }
        case 'negative':
          return { background: 'bg-bb-error' }
        case 'pause':
        case 'paused':
          return { background: 'bg-bb-warning' }
        default:
          return { background: 'bg-neutral-50' }
      }
    },
  },
}
</script>

<style scoped></style>
