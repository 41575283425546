<template>
  <div
    class="text-bb-brand-purple flex items-center gap-2 cursor-pointer mx-6"
    :class="{ 'opacity-50 pointer-events-none': isLoading }"
    @click="updateCampaignList"
  >
    <ic-refresh />
    <p class="font-medium">Update Campaign List</p>
  </div>
</template>

<script>
import IcRefresh from '@/components/icon/brightbid/ic-refresh.vue'
import Toast from '@/components/shared/Toast.vue'

export default {
  name: 'UpdateCampaignButton',
  components: {
    // eslint-disable-next-line
    Toast,
    IcRefresh,
  },
  props: {
    siteId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
    }
  },
  methods: {
    async updateCampaignList() {
      this.isLoading = true
      this.$emit('set-page-loading')
      try {
        await axios.get(`/search/site/${this.siteId}/refresh-campaigns`)
        this.$toast.success({
          component: Toast,
          props: {
            title: 'The campaigns list is now updated!',
            message:
              'Please be patient while we prepare your campaign visualization, this process might take a few minutes.  Feel free to explore other pages and return shortly.',
            type: 'success',
          },
        })
      } catch (error) {
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: 'Failed to update campaign list. Please try again.',
            type: 'error',
          },
        })
      } finally {
        this.isLoading = false
        setTimeout(() => {
          this.$router.push({ name: 'dashboard' })
        }, 5000) // 5seconds
      }
    },
  },
}
</script>

<style scoped></style>
