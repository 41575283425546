<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 12H6V10.6667H2V12ZM2 4V5.33333H14V4H2ZM2 8.66667H10V7.33333H2V8.66667Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IcSort',
  props: {
    size: {
      type: [String, Number],
      default: 16,
    },
  },
}
</script>

<style scoped lang="scss"></style>
