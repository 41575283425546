<template>
  <svg
    v-if="value === 'on'"
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    viewBox="0 0 16 16"
    fill="none"
  >
    <g clip-path="url(#clip0_8034_102550)">
      <path
        d="M3 2L3.28589 3.87616C3.3518 4.30872 3.69128 4.6482 4.12384 4.71411L6 5L4.12384 5.28589C3.69128 5.3518 3.3518 5.69128 3.28589 6.12384L3 8L2.71411 6.12384C2.6482 5.69128 2.30872 5.3518 1.87616 5.28589L0 5L1.87616 4.71411C2.30872 4.6482 2.6482 4.30872 2.71411 3.87616L3 2Z"
        fill="url(#paint0_linear_8034_102550)"
      />
      <path
        d="M3 2L3.28589 3.87616C3.3518 4.30872 3.69128 4.6482 4.12384 4.71411L6 5L4.12384 5.28589C3.69128 5.3518 3.3518 5.69128 3.28589 6.12384L3 8L2.71411 6.12384C2.6482 5.69128 2.30872 5.3518 1.87616 5.28589L0 5L1.87616 4.71411C2.30872 4.6482 2.6482 4.30872 2.71411 3.87616L3 2Z"
        fill="url(#paint1_linear_8034_102550)"
      />
      <path
        d="M6 9.33331L6.25408 11.2092C6.31272 11.6421 6.64553 11.9868 7.07608 12.0606L8.66666 12.3333L7.07608 12.606C6.64553 12.6798 6.31272 13.0245 6.25408 13.4574L6 15.3333L5.74591 13.4574C5.68727 13.0245 5.35446 12.6798 4.92392 12.606L3.33333 12.3333L4.92392 12.0606C5.35446 11.9868 5.68727 11.6421 5.74591 11.2092L6 9.33331Z"
        fill="url(#paint2_linear_8034_102550)"
      />
      <path
        d="M6 9.33331L6.25408 11.2092C6.31272 11.6421 6.64553 11.9868 7.07608 12.0606L8.66666 12.3333L7.07608 12.606C6.64553 12.6798 6.31272 13.0245 6.25408 13.4574L6 15.3333L5.74591 13.4574C5.68727 13.0245 5.35446 12.6798 4.92392 12.606L3.33333 12.3333L4.92392 12.0606C5.35446 11.9868 5.68727 11.6421 5.74591 11.2092L6 9.33331Z"
        fill="url(#paint3_linear_8034_102550)"
      />
      <path
        d="M10.3333 0L10.8271 4.19384C10.9794 5.48708 11.9486 6.53442 13.2262 6.78635L16 7.33333L13.2262 7.88032C11.9486 8.13225 10.9794 9.17959 10.8271 10.4728L10.3333 14.6667L9.83953 10.4728C9.68725 9.17959 8.71809 8.13225 7.44052 7.88032L4.66667 7.33333L7.44052 6.78635C8.71809 6.53442 9.68725 5.48708 9.83953 4.19384L10.3333 0Z"
        fill="url(#paint4_linear_8034_102550)"
      />
      <path
        d="M10.3333 0L10.8271 4.19384C10.9794 5.48708 11.9486 6.53442 13.2262 6.78635L16 7.33333L13.2262 7.88032C11.9486 8.13225 10.9794 9.17959 10.8271 10.4728L10.3333 14.6667L9.83953 10.4728C9.68725 9.17959 8.71809 8.13225 7.44052 7.88032L4.66667 7.33333L7.44052 6.78635C8.71809 6.53442 9.68725 5.48708 9.83953 4.19384L10.3333 0Z"
        fill="url(#paint5_linear_8034_102550)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_8034_102550"
        x1="3"
        y1="2"
        x2="3"
        y2="8"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop
          offset="1"
          stop-color="white"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint1_linear_8034_102550"
        x1="-2.7033"
        y1="-0.684211"
        x2="4.39605"
        y2="-0.278611"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#84FAE4" />
        <stop
          offset="1"
          stop-color="#6366FA"
        />
      </linearGradient>
      <linearGradient
        id="paint2_linear_8034_102550"
        x1="6"
        y1="9.33331"
        x2="6"
        y2="15.3333"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop
          offset="1"
          stop-color="white"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint3_linear_8034_102550"
        x1="0.930398"
        y1="6.6491"
        x2="7.24524"
        y2="6.96979"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#84FAE4" />
        <stop
          offset="1"
          stop-color="#6366FA"
        />
      </linearGradient>
      <linearGradient
        id="paint4_linear_8034_102550"
        x1="10.3333"
        y1="0"
        x2="10.3333"
        y2="14.6667"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop
          offset="1"
          stop-color="white"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint5_linear_8034_102550"
        x1="-0.439556"
        y1="-6.5614"
        x2="12.9879"
        y2="-5.96862"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#84FAE4" />
        <stop
          offset="1"
          stop-color="#6366FA"
        />
      </linearGradient>
      <clipPath id="clip0_8034_102550">
        <rect
          width="16"
          height="16"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>

  <svg
    v-else-if="value === 'off'"
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    viewBox="0 0 16 16"
    fill="none"
  >
    <g clip-path="url(#clip0_8034_102492)">
      <path
        d="M3 2L3.28589 3.87616C3.3518 4.30872 3.69128 4.6482 4.12384 4.71411L6 5L4.12384 5.28589C3.69128 5.3518 3.3518 5.69128 3.28589 6.12384L3 8L2.71411 6.12384C2.6482 5.69128 2.30872 5.3518 1.87616 5.28589L0 5L1.87616 4.71411C2.30872 4.6482 2.6482 4.30872 2.71411 3.87616L3 2Z"
        fill="#ABADB3"
      />
      <path
        d="M6 9.33331L6.25408 11.2092C6.31272 11.6421 6.64553 11.9868 7.07608 12.0606L8.66666 12.3333L7.07608 12.606C6.64553 12.6798 6.31272 13.0245 6.25408 13.4574L6 15.3333L5.74591 13.4574C5.68727 13.0245 5.35446 12.6798 4.92392 12.606L3.33333 12.3333L4.92392 12.0606C5.35446 11.9868 5.68727 11.6421 5.74591 11.2092L6 9.33331Z"
        fill="#ABADB3"
      />
      <path
        d="M10.3333 0L10.8271 4.19384C10.9794 5.48708 11.9486 6.53442 13.2262 6.78635L16 7.33333L13.2262 7.88032C11.9486 8.13225 10.9794 9.17959 10.8271 10.4728L10.3333 14.6667L9.83953 10.4728C9.68725 9.17959 8.71809 8.13225 7.44052 7.88032L4.66667 7.33333L7.44052 6.78635C8.71809 6.53442 9.68725 5.48708 9.83953 4.19384L10.3333 0Z"
        fill="#ABADB3"
      />
    </g>
    <defs>
      <clipPath id="clip0_8034_102492">
        <rect
          width="16"
          height="16"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>

  <svg
    v-else
    :width="size"
    :height="size"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 2L3.28589 3.87616C3.3518 4.30872 3.69128 4.6482 4.12384 4.71411L6 5L4.12384 5.28589C3.69128 5.3518 3.3518 5.69128 3.28589 6.12384L3 8L2.71411 6.12384C2.6482 5.69128 2.30872 5.3518 1.87616 5.28589L0 5L1.87616 4.71411C2.30872 4.6482 2.6482 4.30872 2.71411 3.87616L3 2Z"
      fill="currentColor"
    />
    <path
      d="M5.99992 9.33325L6.25401 11.2092C6.31264 11.6421 6.64545 11.9868 7.076 12.0606L8.66659 12.3333L7.076 12.6059C6.64545 12.6797 6.31264 13.0244 6.25401 13.4573L5.99992 15.3333L5.74583 13.4573C5.6872 13.0244 5.35438 12.6797 4.92384 12.6059L3.33325 12.3333L4.92384 12.0606C5.35438 11.9868 5.6872 11.6421 5.74583 11.2092L5.99992 9.33325Z"
      fill="currentColor"
    />
    <path
      d="M10.3334 0L10.8272 4.19384C10.9795 5.48708 11.9487 6.53442 13.2262 6.78635L16.0001 7.33333L13.2262 7.88032C11.9487 8.13225 10.9795 9.17959 10.8272 10.4728L10.3334 14.6667L9.83961 10.4728C9.68733 9.17959 8.71817 8.13225 7.4406 7.88032L4.66675 7.33333L7.44059 6.78635C8.71816 6.53442 9.68733 5.48708 9.8396 4.19384L10.3334 0Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IcStars',
  props: {
    size: {
      type: [String, Number],
      default: 16,
    },
    value: {
      type: String,
      default: 'default',
    },
  },
}
</script>

<style scoped></style>
