<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.4995 20C11.9495 20 11.4787 19.8042 11.087 19.4125C10.6953 19.0208 10.4995 18.55 10.4995 18C10.4995 17.45 10.6953 16.9792 11.087 16.5875C11.4787 16.1958 11.9495 16 12.4995 16C13.0495 16 13.5203 16.1958 13.912 16.5875C14.3037 16.9792 14.4995 17.45 14.4995 18C14.4995 18.55 14.3037 19.0208 13.912 19.4125C13.5203 19.8042 13.0495 20 12.4995 20ZM12.4995 14C11.9495 14 11.4787 13.8042 11.087 13.4125C10.6953 13.0208 10.4995 12.55 10.4995 12C10.4995 11.45 10.6953 10.9792 11.087 10.5875C11.4787 10.1958 11.9495 10 12.4995 10C13.0495 10 13.5203 10.1958 13.912 10.5875C14.3037 10.9792 14.4995 11.45 14.4995 12C14.4995 12.55 14.3037 13.0208 13.912 13.4125C13.5203 13.8042 13.0495 14 12.4995 14ZM12.4995 8C11.9495 8 11.4787 7.80417 11.087 7.4125C10.6953 7.02083 10.4995 6.55 10.4995 6C10.4995 5.45 10.6953 4.97917 11.087 4.5875C11.4787 4.19583 11.9495 4 12.4995 4C13.0495 4 13.5203 4.19583 13.912 4.5875C14.3037 4.97917 14.4995 5.45 14.4995 6C14.4995 6.55 14.3037 7.02083 13.912 7.4125C13.5203 7.80417 13.0495 8 12.4995 8Z"
      fill="currentColor"
    />
  </svg>
</template>
<script>
export default {
  name: 'IcKebab',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
}
</script>
