<template>
  <tbody class="divide-y divide-bb-neutral-50">
    <tr
      v-for="(item, index) in data"
      :key="index"
      class="h-18 text-sm hover:bg-bb-background-purple-0 hover:font-medium cursor-pointer"
    >
      <td class="px-4 text-left">
        <p class="max-w-xs truncate">{{ item.campaignName }}</p>
      </td>
      <td class="text-center pNumLarge">
        <p>{{ item.previousBudget }}</p>
      </td>
      <td class="text-center pNumLarge">
        <p>{{ item.adjustedBudget }}</p>
      </td>
      <td class="text-center pr-3 pNumLarge">
        <p>{{ formattedDate(item.date) }}</p>
      </td>
    </tr>
  </tbody>
</template>

<script>
import dayjs from 'dayjs'

export default {
  name: 'ActivityHistoryRows',
  components: {},
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {}
  },
  methods: {
    formattedDate(date) {
      return dayjs(date).format('DD/MM/YYYY')
    },
  },
}
</script>
