<template>
  <div class="campaign-budget-card">
    <!-- Add your component content here -->
    <bbBaseCard
      class="bg-white mt-4"
      :checkbox="false"
      :curtain="true"
      @toggleExpansion="expand()"
    >
      <template #first>
        <div class="flex items-center">
          <div>
            <IcGoogleSearchTag
              v-if="!campaign?.pMax"
              :height="16"
              :width="16"
            />
            <IcPMax
              v-if="campaign?.pMax"
              :height="16"
              :width="16"
            />
          </div>
          <div class="ml-2 text-gray-500 text-sm font-bold leading-tight">
            <p v-if="!campaign?.pMax">Search Campaign</p>
            <p v-if="campaign?.pMax">PMAX Campaign</p>
          </div>
        </div>
        <div class="text-gray-700 text-sm font-bold mt-2 max-w-xs truncate">{{ campaign.name }}</div>
      </template>
      <template #content>
        <div
          class="flex justify-between gap-13 hover-target"
          @mouseover="toggleEditBudgetPencil(true)"
          @mouseleave="toggleEditBudgetPencil(false)"
        >
          <!-- AVG SPEND -->
          <div class="mt-2 statsWidth">
            <div class="text-gray-700 text-sm font-bold">Avg. Spend</div>
            <div class="flex mt-2">
              <div class="text-right text-neutral-950 pNumLarge font-bold">{{ campaign.avgSpend }}</div>
              <div class="text-right text-neutral-950 text-sm font-medium ml-2">{{ campaign.currency }}/day</div>
            </div>
          </div>
          <!-- BUDGET -->
          <div class="mt-2 statsWidth">
            <div class="text-gray-700 text-sm font-bold">Budget</div>
            <div class="flex mt-2">
              <div class="text-right text-neutral-950 pNumLarge font-bold">{{ campaign.budget }}</div>
              <div class="text-right text-neutral-950 text-sm font-medium ml-2">{{ campaign.currency }}/day</div>
              <div class="ml-2 pencil-box">
                <div
                  v-if="isEditBudgetPencilVisible"
                  class="dropdown"
                >
                  <ic-pencil
                    class="cursor-pointer hover:text-bb-text-secondary"
                    @click="toggleDropdown"
                  />
                  <div class="dropdown-content">
                    <div class="text-gray-500 text-sm font-normal">Edit daily budget</div>
                    <div class="my-3">
                      <TextInput
                        v-model="dailyBudgetToEdit"
                        :input-id="campaign.name + campaign.id"
                        :input-name="campaign.name + campaign.id"
                      >
                        <template #suffix>
                          <div class="absolute right-0 top-0 z-10 p-2 text-bb-disabled-gray">
                            {{ campaign.currency }}/day
                          </div>
                        </template>
                      </TextInput>
                    </div>
                    <div class="text-gray-500 text-sm">Estimated monthly budget:</div>
                    <div class="text-gray-700 text-sm font-normal tracking-tight pNum">
                      {{ monthlyBudgetToEdit }} {{ campaign.currency }}
                    </div>
                    <div class="flex space-x-4 mt-3 justify-between">
                      <MergeButtonRound
                        button-type="tertiary"
                        @click="closeDropdown"
                      >
                        <span class="text-gray-600"> Cancel </span>
                      </MergeButtonRound>
                      <MergeButtonRound
                        :disabled="isNaN(dailyBudgetToEdit) || dailyBudgetToEdit <= 0"
                        @click="saveNewMonthlyBudget"
                      >
                        Save</MergeButtonRound
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- SUGGESTED BUDGET -->
          <div
            v-if="suggestionState === 'default'"
            class="px-5 py-2 suggestedBudget suggestionCardWidth text-bb-brand-purple rounded-lg border justify-between items-center inline-flex"
          >
            <div>
              <div class="text-sm font-bold flex">
                <IcStars
                  :size="16"
                  class="text-indigo-500 mr-2 mt-1"
                />
                <div>
                  <div>Suggested Budget</div>
                  <div>
                    <div class="flex mt-1 mb-1">
                      <div class="text-right text-neutral-950 pNumLarge font-bold">{{ campaign.suggestedBudget }}</div>
                      <div class="text-right text-neutral-950 text-sm font-medium ml-2">
                        {{ campaign.currency }}/day
                      </div>
                      <!-- arrow with percentage -->
                      <div
                        v-if="newBudgetRatio"
                        class="ml-2 flex items-center"
                      >
                        <ic-arrow
                          :size="10"
                          class="transform my-auto"
                          :class="{
                            '-rotate-90 text-bb-error ': newBudgetRatio < 0,
                            'rotate-90 text-success': newBudgetRatio >= 0,
                          }"
                        ></ic-arrow>
                        <div
                          :class="{
                            'text-red-600': newBudgetRatio < 0,
                            'text-green-600': newBudgetRatio >= 0,
                          }"
                          class="text-xs font-medium mt-1 ml-1"
                        >
                          {{ newBudgetRatio }}%
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <MergeButtonRound
                class="px-3 text-indigo-500 bg-white"
                :button-type="'tertiary'"
                @click="applySuggestedBudget"
                >Apply</MergeButtonRound
              >
            </div>
          </div>
          <!-- ADJUSTED BUDGET -->
          <div
            v-if="suggestionState === 'applied'"
            class="px-5 py-2 bg-gray-100 suggestionCardWidth rounded-lg border justify-start items-center flex gap-16"
          >
            <div>
              <div class="text-sm font-bold">Prev. Budget</div>
              <div class="flex mt-1 mb-1">
                <div class="text-right text-neutral-950 pNumLarge font-medium">{{ campaign?.previousBudget }}</div>
                <div class="text-right text-neutral-950 text-sm font-medium ml-2">{{ campaign.currency }}/day</div>
              </div>
            </div>
            <div>
              <div class="text-sm font-bold">Adjusted Budget</div>
              <div class="flex mt-1 mb-1">
                <div class="text-right text-neutral-950 pNumLarge font-medium">{{ campaign?.adjustedBudget }}</div>
                <div class="text-right text-neutral-950 text-sm font-medium ml-2">{{ campaign.currency }}/day</div>
              </div>
            </div>
          </div>
          <!-- NO SUGGESTIONS -->
          <div
            v-if="suggestionState === 'noSuggestion'"
            class="px-5 py-2 suggestedBudget suggestionCardWidth rounded-lg border justify-start items-center gap-10 inline-flex"
          >
            <div>
              <div class="text-sm font-bold">
                <div class="flex mt-1 mb-1">
                  <div class="text-right text-neutral-950 pNumLarge font-bold">{{ campaign.budget }}</div>
                  <div class="text-right text-neutral-950 text-sm font-medium ml-2">{{ campaign.currency }}/day</div>
                </div>
                <div class="text-right">
                  <span class="text-gray-700 text-sm font-bold">All good! </span>
                  <span class="text-gray-700 text-sm font-normal">There is no new suggestion.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #expanded-content>
        <div class="px-5 py-2 w-full suggestedBudget rounded-lg flex gap-8">
          <div class="mr-2 my-auto bg-white p-2 rounded-md">
            <IcLight :size="24" />
          </div>
          <div class="text-gray-700 text-sm font-medium my-auto">
            We suggest to consider
            {{ newBudgetRatio > 0 ? 'increasing' : newBudgetRatio < 0 ? 'decreasing' : 'keep' }} your budget to
            {{ campaign.suggestedBudget }} {{ campaign.currency }}/day
          </div>
          <!-- Some stats -->
          <div class="bg-white flex my-auto py-1 px-4 rounded-2xl items-center">
            <!-- <div>
              <IcStatsUp :size="24" />
            </div> -->
            <div class="mx-3">
              <span class="text-gray-700 font-normal">Conversions: </span
              ><span class="text-gray-700 font-bold">{{ campaign.metrics_conversions }}</span>
            </div>

            <!-- <div class="bg-green rounded-2xl px-2">
              <div class="text-green text-sm font-bold">+5%</div>
            </div> -->
          </div>
          <div class="bg-white flex my-auto py-1 px-4 rounded-2xl items-center">
            <div class="mx-3">
              <span class="text-gray-700 font-normal">CPA: </span
              ><span class="text-gray-700 font-bold">{{ campaign.inferred_cpa }} {{ campaign.currency }}</span>
            </div>
          </div>
          <div class="text-gray-500 text-xs font-normal my-auto ml-auto">Last 30 days</div>
        </div>
      </template>
    </bbBaseCard>
  </div>
</template>

<script>
import bbBaseCard from '@/components/card/bbBaseCard.vue'
import IcGoogleSearchTag from '@/components/icon/brightbid/google-search-tag.vue'
import IcStars from '@/components/icon/brightbid/ic-stars.vue'
import IcPMax from '@/components/icon/ic-pmax.vue'
import IcLight from '@/components/icon/ic-light.vue'
import IcStatsUp from '@/components/icon/ic-stats-up.vue'
import IcPencil from 'vue-material-design-icons/PencilOutline'
import TextInput from '@/components/input/brightbid/TextInput'
import dayjs from 'dayjs'
import { mapState } from 'vuex'
import IcArrow from '@/components/icon/brightbid/ic-arrow.vue'

export default {
  name: 'CampaignBudgetCard',
  components: {
    bbBaseCard,
    IcGoogleSearchTag,
    IcStars,
    IcPMax,
    IcLight,
    // eslint-disable-next-line vue/no-unused-components
    IcStatsUp,
    IcPencil,
    TextInput,
    IcArrow,
  },
  props: {
    campaign: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isEditBudgetPencilVisible: false,
      dailyBudgetToEdit: 0,
    }
  },
  computed: {
    ...mapState('site', ['selectedSite']),
    monthlyBudgetToEdit() {
      const today = dayjs()
      const daysInMonth = today.daysInMonth()
      return this.dailyBudgetToEdit * daysInMonth
    },
    suggestionState() {
      let state = 'default'
      if (this.campaign?.suggestion_applied === true) {
        state = 'applied'
      } else if (this.campaign?.budget === this.campaign?.suggested_budget) {
        state = 'noSuggestion'
      }
      return state
    },
    newBudgetRatio() {
      if (this.campaign?.budget && this.campaign?.suggested_budget) {
        return Math.round(((this.campaign?.suggested_budget - this.campaign?.budget) / this.campaign?.budget) * 100)
      }
      return 0
    },
  },

  mounted() {
    this.dailyBudgetToEdit = this.campaign.budget
  },
  methods: {
    applySuggestedBudget() {
      this.$emit('save-monthly-budget', this.campaign.suggestedBudget, this.campaign.id)
    },
    saveNewMonthlyBudget() {
      this.$emit('save-monthly-budget', this.dailyBudgetToEdit, this.campaign.id)
    },
    closeDropdown() {
      this.isEditBudgetPencilVisible = false
    },
    toggleDropdown() {},
    expand() {},
    cardClicked() {},
    toggleEditBudgetPencil(value) {
      this.isEditBudgetPencilVisible = value
    },
  },
}
</script>

<style scoped lang="scss">
// All this adjustment to the overflow are to ensure the dropdown is visible properly
.card-base {
  overflow-x: visible;
}
::v-deep .midSec {
  overflow-x: visible;
}
::v-deep .cardBase {
  overflow: visible;
}
.suggestionCardWidth {
  width: 326px;
}
.suggestedBudget {
  background: #f9f8ff;
}
.bg-green {
  background: #effff4;
}
.text-green {
  color: #18b070;
}

.pencil-box {
  width: 24px;
  position: relative;
}

.dropdown {
  position: relative;
  display: block;
}

.dropdown-content {
  display: none;
  position: absolute;
  width: 251px;
  height: 208px;
  padding: 16px;
  background: white;
  box-shadow: -1px 3px 12px rgba(136, 152, 170, 0.15);
  border-radius: 6px;
  z-index: 3;
}

.dropdown:hover .dropdown-content {
  display: block;
}
.statsWidth {
  width: 130px;
}
</style>
