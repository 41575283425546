<template>
  <div class="p-8">
    <div class="w-80 text-gray-700 text-base font-medium">What’s your new total budget?</div>

    <div class="my-2">
      <TextInput
        v-model="dailyBudget"
        input-name="totalBudgetUpdate"
        input-id="totalBudgetUpdateId"
      >
        <template #suffix>
          <div class="absolute right-0 top-0 z-10 p-2 text-bb-disabled-gray">{{ currency }}/day</div>
        </template>
      </TextInput>
    </div>

    <div class="flex mb-5">
      <div class="font-normal">Estimated monthly budget:</div>

      <div class="ml-1">
        <span class="pNum">{{ monthlyBudgetToEdit }}</span
        ><span class="font-medium leading-snug">&nbsp; {{ currency }}/Month</span>
      </div>
    </div>

    <div
      class="self-stretch p-4 noteBox rounded shadow border border-bb-brand-purple justify-start items-center gap-8 inline-flex"
    >
      <div class="justify-start items-start gap-4 flex">
        <div class="relative">
          <ic-info
            class="text-bb-purple-600 transform my-auto"
            :size="24"
          />
        </div>
        <div class="text-bb-brand-purple flex-col justify-start items-start gap-1 inline-flex">
          <div class="self-stretch">
            <span class="font-bold text-bb-purple-600">Note: </span
            ><span class="font-normal">You will get new suggestions based on the new budget entered.</span>
          </div>
        </div>
      </div>
    </div>
    <div class="flex space-x-4 justify-between pt-8">
      <MergeButtonRound
        :button-type="'tertiary'"
        class="text-gray-600"
        @click="$emit('close')"
      >
        <span class="text-gray-600"> Cancel </span>
      </MergeButtonRound>
      <MergeButtonRound
        :disabled="isNaN(dailyBudget) || dailyBudget <= 0"
        @click="updateTotalBudget"
      >
        Get Suggestions
      </MergeButtonRound>
    </div>
    <div
      v-if="isModalLoading"
      class="fixed inset-0 bg-white rounded-md z-50 bg-opacity-75 transition-opacity"
    >
      <div class="flex h-full items-center justify-center">
        <brightbid-loader />
      </div>
    </div>
  </div>
</template>

<script>
import TextInput from '@/components/input/brightbid/TextInput'
import MergeButtonRound from '@/components/btn/MergeButtonRound'
import IcInfo from 'vue-material-design-icons/InformationOutline'
import BrightbidLoader from '@/components/loader/BrightbidLoader.vue'
import dayjs from 'dayjs'

export default {
  name: 'EditBudgetModal',
  components: {
    TextInput,
    IcInfo,
    MergeButtonRound,
    BrightbidLoader,
  },
  props: {
    dailyBudgetToEdit: {
      type: Number,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
    isModalLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dailyBudget: 0,
    }
  },
  computed: {
    monthlyBudgetToEdit() {
      const today = dayjs()
      const daysInMonth = today.daysInMonth()
      return this.dailyBudget * daysInMonth
    },
  },
  mounted() {
    this.dailyBudget = this.dailyBudgetToEdit
  },
  methods: {
    updateTotalBudget() {
      this.$emit('update-total-budget', this.dailyBudget)
    },
  },
}
</script>
<style scoped>
.noteBox {
  background: #f9f8ff;
}
header {
  padding-bottom: 0px !important;
}
</style>
