<template>
  <div
    v-click-outside="clickOutside"
    class="relative"
    :class="{ 'text-bb-disabled-buttons cursor-not-allowed': disabled, 'cursor-pointer': !disabled }"
  >
    <div
      class="flex items-center gap-2 py-2 px-4 border rounded-md"
      @click="disabled ? null : (isDropdownVisible = !isDropdownVisible)"
    >
      <div>
        <slot name="icon"> </slot>
      </div>
      <p>{{ title }} {{ title && selectionLabel ? ':' : '' }}</p>
      <p>{{ selectionLabel }}</p>
      <div
        v-if="selectionLabel"
        @click.stop="$emit('toggle-ascending-descending')"
      >
        <slot name="arrow" />
      </div>
    </div>
    <div
      v-if="isDropdownVisible"
      class="absolute max-h-50 min-w-full bg-white rounded-md shadow-md my-2 dropdown-scrollbar overflow-y-auto"
      :class="dropdownPosition[position]"
    >
      <slot name="dropdown">
        <div
          v-for="option in options"
          :key="option.value"
          class="py-2 px-3 hover:bg-bb-secondary-purple cursor-pointer"
          :class="{
            'text-bb-disabled-buttons': option?.isDisabled,
          }"
          @click="option?.isDisabled ? null : selectItem(option)"
        >
          <p>{{ option.label }}</p>
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DropdownSelect',
  props: {
    selectionValue: { type: Object, required: true },
    position: { type: String, default: 'bottomRightToLeft' },
    title: { type: String, default: '' },
    options: { type: Array, required: true },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      selectedItem: { label: '', value: '' },
      isDropdownVisible: false,
      dropdownPosition: {
        bottomRightToLeft: ['-top-0', 'right-0'],
        bottomLeftToRight: ['-top-0', 'left-0'],
      },
    }
  },
  computed: {
    selectionLabel() {
      return this.selectionValue.label
    },
  },
  methods: {
    selectItem(item) {
      this.selectedItem = item
      this.isDropdownVisible = false
      this.$emit('select-item', this.selectedItem)
    },
    clickOutside() {
      if (!this.isDropdownVisible) return
      this.isDropdownVisible = false
    },
  },
}
</script>
<style scoped lang="scss"></style>
