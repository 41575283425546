<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 14 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Dollar</title>
    <path
      d="M7.73342 10.5333C4.70676 9.74667 3.73342 8.93333 3.73342 7.66667C3.73342 6.21333 5.08009 5.2 7.33342 5.2C9.70676 5.2 10.5868 6.33333 10.6668 8H13.6134C13.5201 5.70667 12.1201 3.6 9.33342 2.92V0H5.33342V2.88C2.74676 3.44 0.666758 5.12 0.666758 7.69333C0.666758 10.7733 3.21342 12.3067 6.93342 13.2C10.2668 14 10.9334 15.1733 10.9334 16.4133C10.9334 17.3333 10.2801 18.8 7.33342 18.8C4.58676 18.8 3.50676 17.5733 3.36009 16H0.426758C0.586758 18.92 2.77342 20.56 5.33342 21.1067V24H9.33342V21.1333C11.9334 20.64 14.0001 19.1333 14.0001 16.4C14.0001 12.6133 10.7601 11.32 7.73342 10.5333Z"
      fill="currentColor"
    />
  </svg>
</template>
<script>
export default {
  name: 'ic-dollar',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
}
</script>
